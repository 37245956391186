@media screen and (max-width: 1280px) {
  .control-sly {
    display: none;
  }
}
li.divided {
  width: 227px;
  height: 325px;
  position: relative;
}
.divided {
  float: left;
  height: 100%;
  margin-right: 10px;
  padding: 0;
  background: none;
  color: #ddd;
  text-align: center;
  cursor: pointer;
}
.divided .divided-image {
  position: absolute;
  margin: auto;
  background-size: 100% 100%;
  width: 227px;
  height: 325px;
}
.divided .divided-info {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  background-size: 100% 100%;
  opacity: 0;
  width: 227px;
  height: 325px;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.divided .divided-info .divided-title {
  font-weight: bold;
  text-align: center;
  color: white;
  font-size: 18px;
  text-decoration: none;
}
.divided .divided-info-button {
  position: relative;
  width: 50%;
  height: 50%;
  top: 27%;
  left: 12%;
}
.divided .divided-info-button .btn-config-buy-ticket {
  width: 172px;
  height: 40px;
  background: url('/images/assets/buy-ticket.png');
  border: none;
  color: white;
  margin: 2px;
  display: inline-block;
  line-height: 25px;
}
.divided .divided-info-button .glyphicon {
  color: white;
}
.divided .divided-info-button .btn-config-watch-trailer:hover {
  background: url('/images/assets/rounded-rectangle-movie-selected-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.divided .divided-info-button .btn-config-buy-ticket:hover {
  background: url('/images/assets/buy-ticket-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.divided .divided-info-button .btn-config-watch-trailer:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.divided .divided-info-button .btn-config-buy-ticket:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.divided .divided-info .divided-info-detail {
  margin: 5px;
  margin-top: 70px;
  color: white;
  text-align: left;
  text-transform: uppercase;
  font-weight: 800;
}
.divided-info-order {
  background-size: 100% 100%;
  width: 90px;
  height: 90px;
  opacity: 2;
  position: relative;
}
.divided-info-order.nowplaying {
  background-image: url("/images/assets/nowplaying.png");
  z-index: 10000;
}
.divided-info-order.upcoming {
  background-image: url("/images/assets/upcoming.png");
  z-index: 10000;
}
.divided:hover .divided-info {
  opacity: 1;
}
.margin-left-100 {
  margin-left: 100px;
}
.margin-right-100 {
  margin-right: 100px;
}
.margin-top-100 {
  margin-top: 100px;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-100 {
  margin: 100px;
}
.margin-left-95 {
  margin-left: 95px;
}
.margin-right-95 {
  margin-right: 95px;
}
.margin-top-95 {
  margin-top: 95px;
}
.margin-bottom-95 {
  margin-bottom: 95px;
}
.margin-95 {
  margin: 95px;
}
.margin-left-90 {
  margin-left: 90px;
}
.margin-right-90 {
  margin-right: 90px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-bottom-90 {
  margin-bottom: 90px;
}
.margin-90 {
  margin: 90px;
}
.margin-left-85 {
  margin-left: 85px;
}
.margin-right-85 {
  margin-right: 85px;
}
.margin-top-85 {
  margin-top: 85px;
}
.margin-bottom-85 {
  margin-bottom: 85px;
}
.margin-85 {
  margin: 85px;
}
.margin-left-80 {
  margin-left: 80px;
}
.margin-right-80 {
  margin-right: 80px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-80 {
  margin: 80px;
}
.margin-left-75 {
  margin-left: 75px;
}
.margin-right-75 {
  margin-right: 75px;
}
.margin-top-75 {
  margin-top: 75px;
}
.margin-bottom-75 {
  margin-bottom: 75px;
}
.margin-75 {
  margin: 75px;
}
.margin-left-70 {
  margin-left: 70px;
}
.margin-right-70 {
  margin-right: 70px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-70 {
  margin: 70px;
}
.margin-left-65 {
  margin-left: 65px;
}
.margin-right-65 {
  margin-right: 65px;
}
.margin-top-65 {
  margin-top: 65px;
}
.margin-bottom-65 {
  margin-bottom: 65px;
}
.margin-65 {
  margin: 65px;
}
.margin-left-60 {
  margin-left: 60px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-60 {
  margin: 60px;
}
.margin-left-55 {
  margin-left: 55px;
}
.margin-right-55 {
  margin-right: 55px;
}
.margin-top-55 {
  margin-top: 55px;
}
.margin-bottom-55 {
  margin-bottom: 55px;
}
.margin-55 {
  margin: 55px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-right-50 {
  margin-right: 50px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-50 {
  margin: 50px;
}
.margin-left-45 {
  margin-left: 45px;
}
.margin-right-45 {
  margin-right: 45px;
}
.margin-top-45 {
  margin-top: 45px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}
.margin-45 {
  margin: 45px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-40 {
  margin: 40px;
}
.margin-left-35 {
  margin-left: 35px;
}
.margin-right-35 {
  margin-right: 35px;
}
.margin-top-35 {
  margin-top: 35px;
}
.margin-bottom-35 {
  margin-bottom: 35px;
}
.margin-35 {
  margin: 35px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-30 {
  margin: 30px;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-right-25 {
  margin-right: 25px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-25 {
  margin: 25px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-20 {
  margin: 20px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-15 {
  margin: 15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-10 {
  margin: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-5 {
  margin: 5px;
}
.margin-left-0 {
  margin-left: 0px;
}
.margin-right-0 {
  margin-right: 0px;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-bottom-0 {
  margin-bottom: 0px;
}
.margin-0 {
  margin: 0px;
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.btn-rounded {
  border-radius: 30px !important;
  padding: 0  !important;
  font-weight: 600  !important;
  border: 2px solid #888;
  width: 30px;
  height: 30px;
  color: #fff  !important;
  font-size: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: none  !important;
}
.btn-rounded i.fa {
  margin: 0;
  padding: 0;
}
.btn-rounded span {
  display: none;
}
.btn-rounded:focus {
  border-color: #00aeef;
  border-radius: 20px;
  outline: none;
}
.btn-rounded:hover {
  border-color: #00aeef;
  background-color: #00aeef;
  color: white;
  border-radius: 20px;
}
.btn-rounded.active {
  background-color: #00aeef;
  color: white;
}
.background-login-register {
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  background: #333;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  z-index: -200;
  top: 0;
  left: 0;
}
.header-center {
  position: absolute;
  width: 100%;
  top: 15px;
}
.header-center .logo-container {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-login {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.content-register {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#playground-container {
  height: 500px;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}
.main {
  margin: 0px 0px;
}
h1.title {
  font-size: 50px;
  font-family: 'Passion One', cursive;
  font-weight: 400;
}
hr {
  width: 10%;
  color: #fff;
}
.form-group {
  margin-bottom: 15px;
}
label {
  margin-bottom: 15px;
}
input,
input::-webkit-input-placeholder {
  font-size: 11px;
  padding-top: 3px;
}
.main-login {
  background-color: #fff;
  /* shadows and rounded borders */
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.form-control {
  height: auto !important;
  padding: 8px 12px !important;
}
.input-group {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.21) !important;
}
#button {
  border: 1px solid #ccc;
  margin-top: 28px;
  padding: 6px 12px;
  color: #666;
  text-shadow: 0 1px #fff;
  cursor: pointer;
  border-radius: 3px 3px;
  box-shadow: 0 1px #fff inset, 0 1px #ddd;
  background: #f5f5f5;
  background: -moz-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  background: linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#eeeeee', GradientType=0);
}
.main-center {
  margin: 0 auto;
  max-width: 400px;
  padding: 10px 40px;
  background: #047fca;
  color: #FFF;
  text-shadow: none;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);
}
span.input-group-addon i {
  color: #009edf;
  font-size: 17px;
}
.login-button {
  font-size: 18px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-button {
  font-size: 18px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-alone-button {
  font-size: 18px;
  text-align: center;
  margin: auto;
  width: 120px;
  position: relative;
}
.top-left {
  float: left;
  width: 90%;
}
.top-left span {
  float: left;
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-size: 13px;
  padding: 10px 0px;
  display: block;
  color: #8a8a8a;
  -moz-transition: 0.4s;
  transition: 0.4s;
  text-decoration: none !important;
}
.top-right {
  float: right;
  width: 10%;
}
.top-right a {
  float: right;
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-size: 13px;
  padding: 10px 10px;
  display: block;
  color: #ff7b00;
  -moz-transition: 0.4s;
  transition: 0.4s;
  text-decoration: none !important;
}
.top-right a:hover {
  text-decoration: none !important;
  color: #ff7b00;
}
.navbar {
  padding: 0;
}
.header-site {
  width: 100%;
}
.logo a img {
  width: 155px;
  padding: 0;
}
a:hover {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
  color: white;
}
.light {
  background-color: white;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
  margin-top: 5px;
}
.amy-search {
  border-radius: 10px;
  display: inline-block;
  margin-left: 50px;
}
.amy-search form {
  margin-bottom: 0;
  display: block;
}
.form-search {
  width: 100%;
  border-radius: 20px;
}
.form-search form {
  margin-bottom: 0;
}
.btn-search {
  border: 0;
  text-align: center;
  font-weight: bold;
  width: 40px;
  height: 40px;
  margin-left: 7px;
  padding: 10 8px;
  border-radius: 50px;
  background-color: #ff7b00;
  color: white;
}
.nav-link {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}
.nav-item a {
  color: #ff7b00;
  font-family: inherit;
}
.nav-item a:hover {
  color: #000000;
  border-bottom: 2px;
  border-bottom-color: #ff7b00;
}
a:hover {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
  color: white;
}
.slide-item {
  position: relative;
  width: 100%;
}
.title-slideshow {
  font-size: 70px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 1000;
  color: white;
  left: 10%;
  overflow: hidden;
  right: 10%;
  top: 43%;
  height: 70px;
  margin-bottom: 0;
}
.title-slideshow .last-word {
  color: #ff7b00;
}
.slide-img {
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}
.desc {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
}
.slide-genre {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
}
.slide-desc {
  margin-top: 20px;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  display: block;
  font-size: 12px lowercase;
}
.cycle-overlay {
  color: white;
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  z-index: 1000;
  left: 10%;
  right: 10%;
  top: 35%;
}
.cycle-left {
  color: white;
  font-size: 30px;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50px;
}
.cycle-right {
  float: right;
  color: white;
  font-size: 30px;
  position: absolute;
  z-index: 1000;
  top: 50%;
  right: 50px;
}
.button-control h4:hover {
  color: #ff7b00;
}
.navigation-tab {
  margin-top: 15px;
}
.navigation-tab ul li a:after {
  font-weight: normal;
  content: "/";
  position: absolute;
  top: 0;
  font-size: 24pt;
  color: #8a8a8a;
  right: -17px;
}
.navigation-tab ul li:last-child a:after {
  display: none;
}
.now-playing-list {
  margin-bottom: 50px;
}
.nav-tab {
  position: relative;
  font-size: 14pt;
  color: #8a8a8a;
}
.nav-tab:hover {
  color: black;
  -moz-transition: 0,5s;
  transition: 0,5s;
}
.navi-item {
  margin-left: 25px;
}
.list-movie {
  margin-top: 30px;
  margin-bottom: 45px;
}
.list-movie-image .movie-image {
  border-bottom: 3px solid #ff7b00;
  position: relative;
  width: 100%;
  height: 352px;
}
.list-movie-image .movie-subtitle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.733);
  color: white;
  opacity: 0;
  z-index: 1000;
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  padding: 10px;
}
.list-movie-image .movie-subtitle .title-now-playing {
  margin-bottom: 10px;
  font-weight: bold;
}
.list-movie-image .movie-subtitle .desc {
  font-size: 13px;
  text-transform: initial;
}
.list-movie-image .movie-subtitle .labels {
  display: block;
  margin-top: 20px;
}
.list-movie-image .movie-subtitle .labels .label {
  margin: 0 5px;
  background: #444;
  color: #ccc;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
}
.list-movie-image .movie-subtitle .labels .label:first-child {
  margin-left: 0;
}
.list-movie-image .movie-subtitle .click-more {
  margin-top: 30px;
}
.list-movie-image:hover .movie-subtitle {
  opacity: 1;
  cursor: pointer;
}
.mv-tag {
  text-align: center;
  width: 35px;
  line-height: 35px;
  font-weight: bold;
  top: 35px;
  right: 0    ;
  color: white;
  position: absolute;
}
.tag-d {
  background-color: #ff0000;
}
.tag-r {
  background-color: #006cf7;
}
.tag-su {
  background-color: #00c400;
}
.poster {
  position: relative;
}
.poster .icon-banner {
  position: absolute;
  top: 0;
  left: 15px;
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
}
.poster .icon-banner.nowplaying {
  background-image: url("/images/nowplaying.png");
}
.poster .icon-banner.upcoming {
  background-image: url("/images/upcoming.png");
}
.now-playing {
  width: 110px;
}
.text-title {
  font-size: 15px;
  margin-bottom: -3px;
  height: 20px;
  overflow: hidden;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.text-release {
  color: #8a8a8a;
  font-size: 10pt;
  font-weight: bold;
}
.btn-trailer-detail {
  width: 100%;
  font-weight: 700;
  float: left;
  font-size: 10px;
  margin-top: 2px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}
.btn-trailer-detail a:hover {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
}
.btn-trailer-detail a:hover .fa {
  background-color: #ff7b00;
  color: white;
}
.btn-trailer-detail a:hover .text-trailer {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
  font-weight: bold;
  color: #ff7b00;
}
.btn-trailer-detail a .text-trailer {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
  font-weight: bold;
  color: #8a8a8a;
}
.btn-trailer-detail a .text-detail {
  color: #8a8a8a;
  margin-left: 8px;
}
.btn-trailer-detail a .fa {
  border-radius: 50px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  width: 25px;
  background-color: #000000;
  color: white;
}
.fa-exclamation {
  border-radius: 50px;
  text-align: center;
  line-height: 25px;
  font-size: 5pt;
  width: 25px;
  background-color: #000000;
  color: white;
}
.text-trailer {
  color: #8a8a8a;
  font-size: 8pt;
  margin-left: 8px;
}
.text-detail {
  font-size: 8pt;
  color: #ff7b00;
  margin-left: 9px;
}
.btn-detail {
  margin-left: 40px;
}
.align-center {
  text-align: center;
}
.nav-menu {
  margin-top: 40px;
  position: relative;
  text-align: left;
  background-color: #e6e6e6;
  width: 100%;
}
.list-menu {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  font-weight: bold;
}
.btn-menu {
  background-color: #e6e6e6;
  border-radius: 0px;
}
.img-icon {
  height: 52px;
}
.menu-name {
  margin-top: 12px;
  color: gray;
  font-size: 13pt;
  font-weight: bold;
}
.img-popcorn-icon {
  position: absolute;
  top: -40px;
  right: 30%;
  z-index: 1000;
}
.menu-item {
  text-align: center;
  left: 30%;
}
.menu-item a:hover {
  background-color: #cecece;
}
.list-menu {
  width: 160px;
}
@media screen and (max-width: 1280px) {
  .control-sly {
    display: none;
  }
}
.top-movie-list {
  margin-top: 20px;
}
.upcoming-movies {
  width: 100%;
  background-image: url("/images/bg-topmovie.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  height: 524px;
  padding-bottom: 25px;
}
.upcoming-movie-header {
  margin-bottom: 40px;
  padding-top: 50px;
  color: #ff7b00;
  text-align: center;
}
.content-title {
  padding-top: 50px;
  font-weight: bold;
}
.slide-upcoming {
  padding: 0;
  height: 100%;
  width: 100%;
}
.poster {
  position: relative;
}
.poster .icon-banner-upcoming {
  position: absolute;
  top: 27px;
  left: 5px;
  width: 80px;
  height: 80px;
  background-size: 100% 100%;
}
.poster .icon-banner-upcoming.nowplaying {
  background-image: url("/images/nowplaying.png");
}
.poster .icon-banner-upcoming.upcoming {
  background-image: url("/images/upcoming.png");
}
.poster .mv-tag-upcoming {
  text-align: center;
  width: 26px;
  line-height: 29px;
  font-weight: bold;
  top: 35px;
  right: 5px;
  color: white;
  position: absolute;
  font-size: 19px;
}
.poster .tag-d {
  background-color: #ff0000;
}
.poster .tag-r {
  background-color: #006cf7;
}
.poster .tag-su {
  background-color: #00c400;
}
.list-mv {
  position: relative;
}
.upcoming-mv-img {
  width: 19%;
  padding-left: 5px;
  margin-bottom: 50px;
}
.img-center {
  width: 24%;
}
.slide-overlay {
  background-color: rgba(0, 0, 0, 0.603);
}
.cast-title-black {
  position: relative;
  color: #ff7b00;
  font-family: inherit;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 3px solid #fe7900;
}
.cast-title-black:after {
  position: absolute;
  content: '';
  width: 42px;
  height: 3px;
  background-image: url(/images/lines.png);
  background-color: transparent;
  background-repeat: no-repeat;
  bottom: -3px;
  right: 0;
}
.frame {
  height: 370px;
}
.frame .slidee li {
  margin-right: 10px;
  float: left;
  padding: 5px;
  width: 204px;
  height: 317px;
}
.control-sly {
  float: left;
}
.control-sly .btn-prev {
  left: -121px;
  top: -12px;
  position: absolute;
  color: #ff7b00;
  font-size: 50px;
  z-index: 1000;
  font-size: 60px;
  line-height: 310px;
}
.control-sly .btn-next {
  right: -121px;
  top: -12px;
  position: absolute;
  float: left;
  color: #ff7b00;
  font-size: 50px;
  z-index: 1000;
  font-size: 60px;
  line-height: 310px;
  padding-left: 0;
}
.preview-content {
  padding: 50px 0;
}
.preview-content .preview-title {
  font-size: 25px;
  color: #ff7b00;
  font-weight: bold;
}
.preview-content .title-videos {
  color: #000000;
  font-weight: bold;
  font-size: 17px;
  margin-top: 10px;
}
.preview-content .desc-videos {
  font-size: 13px;
}
.preview-content .video-link {
  position: relative;
}
.preview-content .play-trailer a {
  padding: 10px;
  position: absolute;
  background-color: white;
  color: #ff7b00;
  border-radius: 5px;
  top: 190px;
  left: 170px;
  font-weight: bold;
}
.preview-content .play-trailer a:hover {
  color: #ff7b00;
  text-decoration: none;
}
.preview-content .image-preview img {
  width: 200px;
  height: 198px;
  margin-top: 19px;
}
.latest-news {
  padding: 100px;
  width: 100%;
  background-image: url('/images/bg-news-ipman.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}
.news-title {
  color: #ff7b00;
  text-align: center;
  margin-bottom: 15px;
}
.news-title h3 {
  font-weight: bold;
}
.title-content {
  text-align: center;
  margin-top: 2px;
  margin-bottom: 18px;
}
.title-content a {
  color: #ff7b00;
  font-size: 16px;
  font-weight: bold;
}
.title-content a span {
  margin-top: 5px;
}
.title-content p {
  color: white;
  font-size: 14px;
  margin-bottom: 7px;
}
.title-content a:hover {
  text-decoration: none;
  color: white;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.more-news {
  margin-top: 50px;
  margin-left: 225px;
}
.more-news a {
  background-color: #ff7b00;
  color: white;
  padding: 5px;
}
.more-news a:hover {
  text-decoration: none;
  color: white;
}
.denpasar-footer {
  background-image: url('/images/footer_bg.png');
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
  background-position: top center;
  background-size: 100% 100%;
}
.denpasar-footer .footer-widgets {
  padding: 100px 0;
  background: rgba(0, 0, 0, 0.603);
}
.denpasar-footer .footer-widgets .logo img {
  padding: 0;
}
.denpasar-footer .footer-widgets .footer-social-list .social-list {
  padding-left: 0;
  list-style-type: none;
}
.denpasar-footer .footer-widgets .footer-social-list .social-list i {
  float: left;
  margin-right: 10px;
  background-color: rgba(128, 128, 128, 0.432);
  color: white;
  width: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
}
.denpasar-footer .footer-widgets .footer-social-list .social-list i:hover {
  background-color: #ff7b00;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.denpasar-footer .footer-widgets .footer-text {
  margin-top: 20px;
  color: #cecece;
  font-size: 14px;
  display: block;
}
.denpasar-footer .footer-widgets .title {
  color: #ff7b00;
}
.denpasar-footer .footer-widgets .link-footer {
  margin-top: 10px;
}
.denpasar-footer .footer-widgets .link-footer a {
  color: #cecece;
  font-size: 14px;
  margin-top: 30px;
}
.denpasar-footer .footer-widgets .link-footer a:hover {
  text-decoration: none;
  color: #ff7b00;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.denpasar-footer .footer-widgets .back-to-top {
  position: absolute;
  top: -26px;
  left: 48%;
  font-size: 60px;
}
.denpasar-footer .footer-widgets .back-to-top a {
  color: #ff7b00;
  /* transition */
  -moz-transition: 1s;
  transition: 1s;
  background: white;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  top: 16px;
  display: block;
}
.denpasar-footer .footer-widgets .back-to-top a:hover {
  color: #ff7b00;
}
.denpasar-footer .footer-widgets .back-to-top a:before {
  content: "\f139";
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  top: -5px;
  left: -1px;
}
.denpasar-footer .footer-widgets .entry-date {
  font-size: 13px;
  color: gray;
  font-weight: bold;
  font-style: italic;
}
.copyright-footer {
  display: inline;
  background-color: rgba(0, 0, 0, 0.603);
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  width: 100%;
}
.copyright-footer .footer-left {
  float: left;
  color: #8a8a8a;
}
.copyright-footer .footer-right a {
  float: right;
  margin-left: 10px;
  color: #8a8a8a;
}
.copyright-footer .footer-right a:hover {
  text-decoration: none;
  color: #ff7b00;
  -moz-transition: 0,4s;
  transition: 0,4s;
}
.content-playing {
  font-family: 'Open Sans', sans-serif;
  margin-top: 20px;
}
.content-playing .btn-prev {
  left: -100px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/left-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
.content-playing .btn-next {
  right: -100px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/right-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
@media screen and (max-width: 1440px) {
  .content-playing .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .content-playing .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: block;
  }
}
.content-playing .btn-prev:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/left-arrow-now-playing-hover.png");
}
.content-playing .btn-next:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/right-arrow-now-playing-hover.png");
}
.content-schedule {
  width: 100%;
  z-index: 1000;
  position: relative;
  height: 600px;
  color: white;
}
.content-schedule .container .row {
  width: 100%;
}
.content-schedule .container .row .col-md-6.schedule {
  z-index: 1400;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  width: 36%;
  margin: 20px;
  padding: 10px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  color: white;
  left: 150px;
  height: 520px;
}
.content-schedule .container .row .schedule .schedule-error {
  margin-top: 180px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 25pt;
  position: absolute;
}
.content-schedule .container .col-md-6.schedule h1.banner {
  font-size: 25px;
  font-weight: bolder;
  padding: 10px;
  color: white;
  margin: 0;
  background: #047fca;
  width: 100%;
  text-shadow: #000 0 1px 1px;
}
.schedule-container {
  position: absolute;
  top: 111px;
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
}
.schedule-container .container {
  background: none !important;
}
.schedule-container .container .schedule {
  border: none;
  background: none !important;
}
.schedule {
  padding: 10px;
}
.schedule .bx-wrapper {
  background: none;
  box-shadow: none;
  border: none;
}
.schedule .slide {
  position: relative;
}
.schedule .slide .bx-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.schedule .slide .slider-controls {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 100;
}
.schedule .slide .slider-controls .left-control {
  width: 30px;
  height: 30px;
  float: left;
}
.schedule .slide .slider-controls .right-control {
  width: 30px;
  height: 30px;
  float: right;
}
.schedule .header {
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-family: sans-serif;
  font-weight: bolder;
  margin-bottom: 5px;
}
.schedule .content {
  display: block;
}
.schedule .content ul.content-schedule {
  height: auto;
  list-style: none;
  background: none;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}
.schedule .content ul.content-schedule li {
  overflow: hidden;
}
.schedule .content ul.content-schedule li .left-side {
  float: left;
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  line-height: 50px;
}
.schedule .content ul.content-schedule li .right-side {
  width: 290px;
  float: left;
  display: inline-block;
}
.schedule .content ul.content-schedule li .right-side .title-row {
  overflow: hidden;
  position: relative;
}
.schedule .content ul.content-schedule li .right-side .time-row {
  overflow: hidden;
}
.schedule .content ul.content-schedule li .right-side .time-row .time {
  float: left;
  margin: 0 2px;
}
.schedule .content ul.content-schedule li .right-side .time-row .time:first-child {
  margin-left: 0;
}
.schedule .content ul.content-schedule li .right-side .time-row .time:after {
  content: " - ";
}
.schedule .content ul.content-schedule li .right-side .time-row .time:last-child:after {
  content: "";
}
.schedule .header-date {
  overflow: hidden;
}
.schedule .header-date .header-date-text {
  display: inline-block;
  line-height: 30px;
  margin: 5px;
  font-size: 15px;
  color: white;
}
.schedule .header-date .button-list {
  display: inline-block;
  margin: 5px 0;
  float: right;
}
.content-schedule .col-md-6 .schedule-list {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 420px;
}
.content-schedule .col-md-6 .schedule-list li {
  position: relative;
  overflow: hidden;
  display: table;
  width: 100%;
}
.content-schedule .col-md-6 .schedule-list li .left {
  width: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0;
}
.content-schedule .col-md-6 .schedule-list li .left .studio {
  font-size: 50px;
  font-family: Oswald, arial, Georgia, serif;
}
.content-schedule .col-md-6 .schedule-list li .right {
  display: table-cell;
  vertical-align: middle;
}
.content-schedule .col-md-6 .schedule-list li .right .film {
  font-family: Oswald, arial, Georgia, serif;
  text-transform: uppercase;
  padding-bottom: 0;
  font-size: 12pt;
}
.content-schedule .col-md-6 .schedule-list li .right .time {
  display: inline-block;
  margin-right: 8px;
}
a.disabled {
  pointer-events: none !important;
  cursor: default !important;
}
.content-schedule-article {
  width: 100%;
  background: transparent;
  z-index: 1000;
  position: absolute;
  top: 700px;
  left: 0;
  right: 0;
  height: 600px;
  color: white;
}
.content-schedule-article .container .row {
  width: 100%;
}
.content-schedule-article .container .row .col-md-6.schedule {
  z-index: 1400;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  width: 36%;
  margin: 20px;
  padding: 10px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  color: white;
  left: 150px;
  height: 520px;
}
.content-schedule-article .container .col-md-6.schedule h1.banner {
  font-size: 25px;
  font-weight: bolder;
  padding: 10px;
  color: white;
  margin: 0;
  background: #047fca;
  text-shadow: #000 0 1px 1px;
}
.content-schedule-article .col-md-6 .schedule-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 420px;
}
.content-schedule-article .col-md-6 .schedule-list li {
  position: relative;
  overflow: hidden;
  display: table;
  width: 100%;
}
.content-schedule-article .col-md-6 .schedule-list li .left {
  width: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0;
}
.content-schedule-article .col-md-6 .schedule-list li .left .studio {
  font-size: 50px;
  font-family: Oswald, arial, Georgia, serif;
}
.content-schedule-article .col-md-6 .schedule-list li .right {
  display: table-cell;
  vertical-align: middle;
}
.content-schedule-article .col-md-6 .schedule-list li .right .film {
  font-family: Oswald, arial, Georgia, serif;
  text-transform: uppercase;
  padding-bottom: 0;
  font-size: 12pt;
}
.content-schedule-article .col-md-6 .schedule-list li .right .time {
  display: inline-block;
  margin-right: 8px;
}
.about-header {
  background-color: white;
  text-align: center;
  position: relative;
}
.about-header:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
  background-image: url("/images/bali2.png");
  background-repeat: no-repeat;
  background-position: bottom;
  -ms-background-size: cover;
  background-size: cover;
  z-index: 0;
}
.about-header .welcome-image {
  width: 100%;
  margin-top: 44px;
}
.about-header .about-header-title {
  padding-top: 100px;
  color: #000000;
  font-weight: 700;
  margin-top: 26px;
}
.about-content {
  background-color: #313131;
}
.about-content .about-content-title {
  font-weight: bold;
  color: white;
  margin-left: 110px;
  margin-top: 155px;
}
.about-content .about-content-text {
  color: #fff;
  width: 450px;
  margin-top: 15px;
  margin-left: 110px;
  font-size: 16px;
}
.studio-review {
  padding: 30px 0;
}
.studio-review .row .col-md-3 .studio-header {
  font-weight: bold;
  display: block;
  margin-top: 40%;
}
.studio-review .row .col-md-3 span {
  font-size: 16px;
}
.studio-review .row .col-md-3 .img-studio {
  width: 100%;
}
.about-maps {
  background-color: #313131;
}
.about-maps .about-maps-title {
  font-weight: bold;
  color: white;
  margin-left: 110px;
  margin-top: 177px;
}
.about-maps .about-maps-text {
  color: #fff;
  width: 450px;
  margin-top: 15px;
  margin-left: 110px;
  font-size: 16px;
}
.logo-image {
  width: 350px;
}
.about-technology {
  background-color: rgba(255, 118, 0, 0.85);
  height: 500px;
  text-align: center;
}
.about-technology .container {
  padding-top: 120px;
}
.about-contact {
  text-align: center;
}
.about-contact .about-contact-title {
  font-weight: bold;
  color: #000000;
  margin-left: 110px;
  margin-top: 135px;
  margin-bottom: 22px;
}
.about-contact .about-contact-text {
  color: #000000;
  width: 450px;
  margin-top: 0;
  margin-left: 110px;
  margin-bottom: 10px;
  font-size: 20px;
}
.about-contact .about-contact-text a {
  color: #000000;
}
.about-contact .facebook {
  color: white;
}
.about-contact .instagram {
  color: white;
}
.about-contact .twitter {
  color: white;
  background-color: #1dcaff;
}
.barco {
  margin-top: 65px;
}
.dolby {
  margin-top: 65px;
}
.top-movie-list {
  margin-top: 20px;
}
.upcoming-movies {
  width: 100%;
  background-image: url("/images/bg-topmovie.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.upcoming-movie-header {
  margin-bottom: 40px;
  color: #ff7b00;
  text-align: center;
}
.content-title {
  padding-top: 50px;
  font-weight: bold;
}
.list-mv {
  position: relative;
}
.upcoming-mv-img {
  width: 19%;
  padding-left: 5px;
  margin-bottom: 50px;
}
.img-center {
  width: 24%;
}
.slide-overlay {
  background-color: rgba(0, 0, 0, 0.603);
}
.content-footer-about {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  background-size: 100%;
  z-index: 1000;
  position: absolute;
  color: white;
  top: 2500px;
  height: 160px;
}
.content-footer-about .line-title {
  display: block;
  font-weight: 300;
}
.content-footer-about .container table.footer-table {
  color: white;
  width: 100%;
  font-weight: 100;
}
.content-footer-about .container table.footer-table > tbody > tr > td {
  padding: 5px 15px;
  font-size: 14px;
  position: relative;
}
.content-footer-about .container .btn-send {
  margin-left: 330px;
  background: #00aeef;
  font-weight: 900;
  font-size: 12pt;
  border: 1px;
  border-radius: 0;
  display: inline;
  padding: 8px 20px;
}
.content-footer-about .container .row .col-md-12 .image-footer {
  position: absolute;
  left: 450px;
  top: 30px;
}
/* kelas button pada tab */
.btn-tab {
  border-bottom: 5px solid #f44336;
  color: white;
  padding: 15px 20px 15px 20px;
  display: inline-block;
  text-align: center;
}
/* kelas artikel pada halaman article.html */
.article-container {
  display: block;
  top: 120px;
  position: absolute;
  width: 100%;
  z-index: 2000;
}
.tooltip-templates {
  display: none;
}
.article-container .container {
  padding: 15px;
}
.article-container .article-back {
  background-color: rgba(0, 0, 0, 0.6);
}
.article-container .article-cover {
  position: relative;
  padding-left: 15px;
}
.article-container .article-cover > .image-cover {
  width: 180px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 236px;
  display: block;
  background-color: #000;
}
.article-container .article-cover > .cover-mask {
  position: absolute;
  top: 0;
  left: 0;
}
.article-container .article-cover > .player-fullscreen-hover {
  position: absolute;
  opacity: 0;
  background: url(/images/assets/cinema-background.png);
  background-size: 100% 100%;
  height: 236px;
  top: 0;
  left: 15px;
  right: 15px;
  width: 180px;
}
.article-container .article-cover > .player-fullscreen-hover > .btn-play-fullscreen {
  position: absolute;
  background: url("/images/assets/btn-play.png");
  height: 100px;
  width: 100px;
  display: block;
  top: 30%;
  left: 23%;
  border: 0;
  border-radius: 0;
  outline: 0;
}
.article-container .article-cover:hover > .player-fullscreen-hover {
  opacity: 1;
}
.article-container .article-content {
  color: white;
}
.article-container .article-content .share-button .facebook-icons {
  position: absolute;
  font-size: 25px;
  top: 5px;
  right: 11px;
}
.article-container .article-content .share-button .twitter-icons {
  position: absolute;
  font-size: 25px;
  top: 6px;
  right: 6px;
}
.article-container .article-content .movie-tags {
  list-style: none;
  padding: 0;
  margin: 0;
}
.article-container .article-content .movie-tags li {
  padding: 0 10px;
  display: inline-block;
  border-right: 1px solid white;
}
.article-container .article-content .movie-tags li:first-child {
  padding-left: 0;
}
.article-container .article-content .movie-tags li:last-child {
  border: none;
}
.article-container .article-content .cast-label {
  margin: 0 0 5px 0;
}
.article-container .article-content .cast-catalog {
  list-style: none;
  padding: 0;
  margin: 0;
}
.article-container .article-content .cast-catalog li {
  display: inline-block;
}
.article-container .article-content .cast-catalog li:first-child {
  margin-left: 0;
}
.article-container .article-content .cast-catalog li .artist-link:hover > img {
  cursor: pointer;
  opacity: 1;
}
.article-container .article-content .cast-catalog li .artist-link > img {
  width: 50px;
  height: 60px;
  background: black;
  border: none;
  opacity: 0.8;
}
.article-container .article-content .article-title {
  text-transform: uppercase;
  font-size: 29px;
}
.article-container .article-content .article-paragraph {
  padding-top: 0px;
  line-height: 30px;
  height: 150px;
  margin-top: 5px;
}
.article-container .article-content .article-spec-table {
  color: white;
  width: 100%;
  background: none;
}
.article-container .article-content .article-spec-table tbody > tr > td {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  font-size: 14px;
  position: relative;
  background: none;
}
.article-container .article-content .article-spec-table tbody > tr > td:first-child > td {
  border-top: 1px solid #ddd;
}
.article-container .article-content .article-spec-table tbody > tr > td:first-child > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  top: -10px;
  right: 0;
}
.article-container .article-content .article-spec-table tbody > tr > td:first-child > td:last-child:before {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -10px;
  right: 0;
}
.article-container .article-content .article-spec-table tbody > tr > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -10px;
  right: 0;
}
.article-container .article-content .article-schedule {
  padding: 15px;
}
.table-purchase {
  border: 0;
  border-radius: 0;
  margin-top: 20px ;
  margin-left: 5px ;
}
.table-purchase .purchase-studio {
  margin-top: -2.5px;
  margin-left: -2.5px;
}
.table-purchase .purchase-time {
  font-size: 10pt;
  margin-left: 5px;
}
.button-normal {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
ul.button-list {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  display: block;
}
ul.button-list li {
  display: inline-block;
  margin: 2px 5px;
}
ul.button-list li:first-child {
  margin-left: 0;
}
ul.button-list li.header {
  width: 150px;
}
ul.button-list li a {
  padding: 4px 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-family: sans-serif;
}
ul.button-list li a:hover {
  text-decoration: none;
}
.button-modal-studio {
  background: #047fca;
  border: 0;
  padding: 5px 10px;
  font-size: 10pt;
  margin-left: 5px;
  margin-right: 5px;
}
.button-modal-studio:hover {
  background: #fc9230;
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.button-modal-studio:focus {
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.modal-studio {
  top: 15%;
  position: fixed;
  color: white;
}
.modal-studio-content {
  color: white;
  background: rgba(0, 0, 0, 0.9);
  border: none;
  overflow: hidden;
}
.modal-studio-h3 {
  color: white;
  padding-top: 0;
  padding-bottom: 0;
}
.modal-studio .modal-dialog .modal-content .modal-header {
  position: relative;
  display: block;
}
h6.address {
  padding: 0;
  margin: 0;
}
.btn-select-studio {
  border-radius: 0;
  border: none;
  background: #047fca;
  font-size: 12pt;
}
.btn-select-studio:hover {
  border-radius: 0;
  border: none;
  background: #fc9230;
}
.studio-circle-o {
  margin-top: 2px;
  font-size: 25pt;
}
.button-modal-datepicker {
  background: #047fca;
  border: 0;
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.button-modal-datepicker:hover {
  background: #fc9230;
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.button-modal-datepicker:focus {
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.modal-datepicker {
  top: 15%;
  position: fixed;
  color: white;
}
.modal-datepicker-background {
  background: rgba(255, 255, 255, 0.9);
}
.modal-datepicker .modal-dialog .modal-content .modal-header {
  position: relative;
  display: block;
}
.modal-datepicker .modal-dialog .modal-content .modal-body .datepicker {
  width: 100%;
  margin-top: -10px;
  margin-left: 15px;
}
.fullscreen-manager-logo {
  left: 25px;
  bottom: 25px;
  display: inline-block;
  position: fixed;
  opacity: 0.5;
  filter: alpha(opacity=50);
  z-index: 100;
}
.fullscreen-manager-logo:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fullscreen-manager-logo .btn-fullscreen-logo {
  background: url("/images/assets/logo.png");
  width: 250px;
  height: 62px;
  border: 0;
  border-radius: 0;
  outline: 0;
}
.fullscreen-manager {
  z-index: 100;
  display: inline-block;
  height: 120px;
  bottom: 25px;
  right: 25px;
  position: fixed;
  color: white;
  opacity: 0.5;
  filter: alpha(opacity=50);
  padding: 0 8px;
  background-color: rgba(100, 100, 100, 0.5);
}
.fullscreen-manager:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fullscreen-manager .back-set {
  position: absolute;
}
.fullscreen-manager .back-set .content-detail {
  position: absolute;
}
.fullscreen-manager button {
  background: none;
  border: none;
  border-radius: 0;
  color: white;
  outline: 0;
  padding: 5px 10px;
  text-align: center;
}
.fullscreen-manager button:hover {
  background: #fc9230;
  border: none ;
  border-radius: 5px;
  color: white;
  outline: 0;
}
.fullscreen-manager .content-cover {
  position: absolute;
  left: -105px;
  top: 0px;
}
.fullscreen-manager .content-cover > .image-cover {
  width: 90px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 120px;
}
.slideshow-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  background-size: 100% 100%;
  height: 100%;
  z-index: -200;
}
.slideshow-container .cycle-slideshow {
  width: 100%;
  height: 100%;
  position: fixed;
}
.cycle-slideshow img {
  width: 100%;
  height: 600px;
}
.cycle-pager {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
#alt-caption {
  position: absolute;
  bottom: 10px;
  width: 100%;
  color: white;
  text-align: center;
}
.slideshow-header {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  bottom: 50px;
  width: 100%;
  color: white;
  z-index: 10000;
}
.slideshow-header .no-caption .caption-decoration {
  display: none;
}
.slideshow-header .container #alt-caption {
  position: relative;
  color: white;
  text-align: left;
}
#alt-caption p {
  text-align: justify;
}
.slideshow-header .container .cycle-slideshow {
  position: absolute;
  z-index: 1050;
  width: 75%;
  height: 100%;
}
.button-slideshow-header {
  margin-top: 0;
}
.info-slideshow-header {
  font-weight: bolder;
  position: absolute;
  color: white;
  top: 450px;
}
.info-slideshow-header .info {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  padding: 10px;
  font-size: 14px;
  opacity: 0;
  z-index: 1;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
  height: 100%;
}
.cycle-slide {
  padding: 0;
}
.tint {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  height: 100%;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+28,000000+28,000000+37,000000+100&0.7+0,0.1+16,0+25,0.1+25,0.5+57,1+91 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 16%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.14) 28%, rgba(0, 0, 0, 0.25) 37%, rgba(0, 0, 0, 0.5) 57%, #000000 91%, #000000 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 16%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.14) 28%, rgba(0, 0, 0, 0.25) 37%, rgba(0, 0, 0, 0.5) 57%, #000000 91%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}
.background-container {
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  z-index: -200;
  top: 0;
  left: 0;
}
.background-container .container .background-body {
  background: white;
  z-index: 1000;
  position: absolute;
  top: 80px;
  height: 100%;
  width: 68%;
  border: 2px darkgrey outset;
  border-radius: 5px;
}
.widget-upcoming {
  height: 670px;
  width: 110%;
  margin-top: 30px;
  margin-left: -20px;
  overflow: hidden;
}
.brick {
  width: 227px;
  height: 325px;
  position: relative;
}
.brick .brick-image {
  position: absolute;
  margin: auto;
  background-size: 100% 100%;
  width: 227px;
  height: 325px;
}
.brick .brick-info {
  color: white;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  background-size: 100% 100%;
  opacity: 0;
  width: 227px;
  height: 325px;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.brick .brick-info .desc {
  font-size: 13px;
  color: white;
  padding: 10px;
  padding-top: 15px;
}
.brick .brick-info .click-more {
  color: white;
  font-weight: bold;
  font-size: 15px;
  padding-top: 30px;
}
.brick .brick-info-button {
  position: relative;
  width: 50%;
  height: 50%;
  top: 27%;
  left: 12%;
}
.brick .brick-info-button .btn-config-watch-trailer {
  width: 172px;
  height: 40px;
  background: url('/images/assets/rounded-rectangle-movie-selected.png') no-repeat;
  border: none;
  color: white;
  display: inline-block;
  margin: 2px;
  line-height: 25px;
}
.brick .brick-info-button .btn-config-buy-ticket {
  width: 172px;
  height: 40px;
  background: url('/images/assets/buy-ticket.png');
  border: none;
  color: white;
  margin: 2px;
  display: inline-block;
  line-height: 25px;
}
.brick .brick-info-button .glyphicon {
  color: white;
}
.brick .brick-info-button .btn-config-watch-trailer:hover {
  background: url('/images/assets/rounded-rectangle-movie-selected-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.brick .brick-info-button .btn-config-buy-ticket:hover {
  background: url('/images/assets/buy-ticket-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.brick .brick-info-button .btn-config-watch-trailer:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.brick .brick-info-button .btn-config-buy-ticket:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.brick .brick-info .brick-info-detail {
  margin: 5px;
  margin-top: 70px;
  color: white;
  text-align: left;
  text-transform: uppercase;
  font-weight: 800;
}
.brick .brick-info-order {
  background: url("/images/assets/upcoming.png");
  background-size: 100% 100%;
  width: 75px;
  height: 75px;
  opacity: 2;
  position: relative;
}
.brick:hover .brick-info {
  opacity: 1;
  color: white;
}
.circle {
  width: 104px;
  height: 104px;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
}
.circle .content {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 104px;
  text-align: center;
  width: 104px;
  height: 104px;
  font-size: 25pt;
  font-weight: bold;
}
.circle .circle-label {
  position: absolute;
  bottom: -20px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 10pt;
  display: block;
  width: 100%;
}
.circle.orange-circle {
  background-image: url('/images/assets/orange.png');
}
.circle.red-circle {
  background-image: url('/images/assets/red.png');
}
.circle.blue-circle {
  background-image: url('/images/assets/blue.png');
}
.circle.green-circle {
  background-image: url('/images/assets/green.png');
}
.circle-article {
  width: 74px;
  height: 74px;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
}
.circle-article .content {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 74px;
  text-align: center;
  width: 74px;
  height: 74px;
  font-size: 17pt;
}
.circle-article .circle-label {
  position: absolute;
  bottom: -20px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 7pt;
  display: block;
  width: 100%;
}
.circle-article.orange-circle {
  background-image: url('/images/assets/orange.png');
}
.circle-article.red-circle {
  background-image: url('/images/assets/red.png');
}
.circle-article.blue-circle {
  background-image: url('/images/assets/blue.png');
}
.circle-article.green-circle {
  background-image: url('/images/assets/green.png');
}
.btn-header {
  background: none;
  border: none;
  border-radius: 0;
  margin: 0;
  display: inline-block;
  outline: none;
  color: white;
}
.btn-header:hover {
  color: white;
  border: none;
  outline: none;
  border-radius: 0px;
  background: rgba(0, 174, 239, 0.5);
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.btn-header:active {
  border: none;
  border-radius: 0px;
  outline: none;
  color: white;
}
.btn-header:focus {
  border: none;
  border-radius: 0;
  outline: none;
  color: white;
}
.btn-control-header {
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 10px;
}
.new:after {
  content: "";
  background-image: url(/images/assets/red_new.png);
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  display: block;
  float: right;
  word-wrap: break-word;
  margin-top: -4px;
}
header.header {
  display: block;
  position: relative;
  height: 114px;
}
.container.error-container {
  width: 100%;
  height: initial !important;
  background: #333;
}
.container.error-container .info-error {
  margin: 10% 50px;
}
.container.error-container .info-error .title-container {
  color: #fff;
}
.container.error-container .info-error .title-container .error-title {
  line-height: 1;
  margin: 0;
  font-weight: 600;
  font-size: 60px;
}
.container.error-container .info-error .error-message {
  color: #fff;
  margin: 15px 0;
}
.container.error-container .info-error .error-message h4 {
  margin: 0;
  line-height: 16px;
  font-weight: 400;
  font-size: 17px;
}
.container.error-container .info-error .back-to-home {
  /*
			a.btn{
				margin-left: 140px;
				margin-top: 55px;
				&:hover {
				    color: #333;
				    text-decoration: none;
				    color: #337ab7;
				}
				i.fa{
					font-size: 85px;
				}
				span{
					position: absolute;
					margin-top: 10px;
					font-size: 25px;
					font-weight: bold;
					font-family: sans-serif;
					margin-left: -20px;
				    letter-spacing: -3px;
				}
			}
		}*/
}
.container.error-container .image-container {
  float: right;
  width: 600px;
  margin: 25% 0;
}
.container.error-container .image-container img {
  width: 100%;
}
.container.content-footer {
  height: 25%;
}
.container.content-footer .footer {
  background: #000;
  height: 168px;
  position: relative;
  padding: 0 35%;
}
.container.content-footer .footer .main-footer {
  margin-top: 30px;
  width: 500px;
}
.container.content-footer .footer .main-footer .contact-container {
  background: url(/images/assets/cinema-background.png) no-repeat;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 17px;
  padding: 5px;
}
.container.content-footer .footer .main-footer .contact-container .logo-container {
  width: 200px;
}
.container.content-footer .footer .main-footer .contact-container .logo-container img {
  width: 100%;
}
.container.content-footer .footer .main-footer .contact-container .telp-container .place {
  font-size: 20px;
}
.desktop-error {
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #090a0f 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #090a0f 100%);
  position: fixed;
  height: 100%;
  width: 100%;
}
.desktop-error img.error {
  width: 70%;
  position: absolute;
  left: 15%;
  margin-top: 50px;
}
.desktop-error h1.tittle {
  font-size: 110px;
  font-weight: 700;
  text-shadow: 6px 7px 4px #000;
  margin-top: 150px;
}
.desktop-error h1.page-not-found {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}
.desktop-error .desktop-error-footer {
  position: absolute;
  bottom: 0px;
  height: 27%;
  width: 100%;
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #0c0e14 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #0c0e14 100%);
  text-align: center;
}
.desktop-error .desktop-error-footer p {
  font-size: 14px;
  font-weight: bold;
}
.desktop-error .desktop-error-footer img {
  width: 228px;
}
.desktop-error .desktop-error-footer .telp-error {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 100;
}
.data-notfound {
  font-size: 70px;
  color: red;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 70px 0;
}
.col-lg-12 .notice-idn {
  display: block;
  text-align: center;
  color: #333;
  margin-top: 10px;
  margin-bottom: 0;
}
.col-lg-12 .notice-eng {
  display: block;
  text-align: center;
  color: #333;
  font-style: italic;
}
.schedule {
  padding-bottom: 30px;
}
.schedule .control-label {
  line-height: 39px;
  text-align: right;
  font-weight: bold;
}
.schedule .loader-container {
  padding: 50px 20px;
  text-align: center;
}
.schedule .icon-container {
  padding: 20px;
}
.schedule .control .title-dropdown {
  text-align: center;
}
.schedule .control select.form-control {
  border: 1px solid #ff7b00;
  border-radius: 0;
}
.schedule-panel {
  width: 50%;
  padding: 15px;
  float: left;
}
.schedule-panel .main-content {
  overflow: hidden;
  border: 1px solid #ff7b00;
}
.schedule-panel .main-content .film-cover {
  width: 40%;
  float: left;
  height: 360px;
  border-right: 1px solid #ff7b00;
  background-position: center;
  background-size: cover;
  position: relative;
}
.schedule-panel .main-content .film-cover .icon-watch-trailer {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.507);
  opacity: 0;
  z-index: 1000;
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.schedule-panel .main-content .film-cover .icon-watch-trailer .icon-watch-trailer-img {
  padding-top: 130px;
}
.schedule-panel .main-content .film-cover:hover .icon-watch-trailer {
  opacity: 1;
  cursor: pointer;
}
.schedule-panel .main-content .schedule-content {
  width: 60%;
  float: left;
}
.schedule-panel .main-content .schedule-content .schedule-header {
  padding: 7px 20px 5px 20px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title a {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  text-transform: uppercase;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title a:hover {
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title a.long-title {
  font-size: 21px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-long a {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  text-transform: uppercase;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-long a:hover {
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-long a.long-title {
  font-size: 21px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-very-long a {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  text-transform: uppercase;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-very-long a:hover {
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-very-long a.long-title {
  font-size: 18px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-duration {
  font-size: 12px;
  color: #ff7b00;
  font-weight: 400;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-duration .displaynone {
  display: none;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description table {
  width: 100%;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description table tr > td {
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
  font-size: 12px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description table tr > td.emphasis {
  font-weight: bold;
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding {
  padding-top: 14px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding table {
  width: 100%;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding table tr > td {
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
  font-size: 12px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding table tr > td.emphasis {
  font-weight: bold;
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-footer {
  padding: 20px;
}
.schedule-panel .main-content .schedule-content .schedule-footer .studio-label {
  color: #ff7b00;
  margin-top: -14px;
  font-size: 18px;
  display: inline-block;
  position: relative;
}
.schedule-panel .main-content .schedule-content .schedule-footer .studio-label a {
  margin-bottom: 0;
  font-weight: 700;
  color: #ff7b00;
  font-size: 22px!important;
}
.schedule-panel .main-content .schedule-content .schedule-footer .studio-label a:hover {
  color: #333;
  -moz-transition-duration: 0.3s;
       transition-duration: 0.3s;
}
.schedule-panel .main-content .schedule-content .schedule-footer .showtime-list {
  margin-top: 10px;
}
.schedule-panel .ul-showtime {
  margin: 0;
}
.schedule-panel .ul-showtime .li-showtime {
  padding: 2px;
  width: 25%;
  display: block;
  float: left;
}
.schedule-panel .showtime-denpasar {
  background-color: #ff7b00;
  font-size: 13px;
  color: white;
  background-color: #333333;
  border: 1px solid #333333;
  float: left;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.schedule-panel .showtime-denpasar:hover {
  background-color: #ff7b00;
  color: white;
  border: 1px solid #ffb066;
}
.bg-partnership {
  background: url("/images/assets/bg-partnership.png");
  background-size: 100% 100%;
}
.partner {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  position: relative;
}
.partner .partner-title {
  font-weight: bold;
  font-size: 60px;
}
.partner img {
  width: 100%;
}
.partner .header-image {
  opacity: 0.7;
}
.partner .partner-join {
  position: relative;
  bottom: 330px;
  text-align: center;
}
.partner .grow-business {
  display: inline;
  position: absolute;
  top: 370px;
  width: 100%;
  left: 15px;
}
.partner .grow-business .panel-partnership {
  padding: 5px 10px 10px 65px;
  color: #000;
  background: #fff;
  margin-top: 5%;
  height: 150px;
}
.partner .grow-business .col-md-3 {
  padding: 0;
}
.partner .grow-business .col-md-3 .contact {
  background: #3e24b1;
  width: 100%;
  height: 193px;
  color: white;
  padding: 10px 10px 55px 20px;
}
.partner .grow-business .col-md-3 a.link {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
}
.partner .grow-business .col-md-3 a.link .contact:hover {
  background: #2507aa;
  cursor: pointer;
  color: white;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.partner .grow-business .col-md-3 a.link .contact:hover h3.hover {
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.partner .body-partner {
  color: #000;
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.partner .body-partner .our-partnership {
  line-height: 5px;
}
.partner .body-partner .our-partnership .offer {
  font-size: 22px;
}
.partner .body-partner .blog {
  font-size: 16px;
  padding: 10px;
  line-height: 30px;
}
.partner .body-partner .img-blog {
  width: 544px;
  height: 365px;
  margin-bottom: 20px;
}
.partner .body-partner .bold-blog {
  font-weight: bold;
  padding: 15px;
  margin-top: 60px;
}
.partner .gallery {
  text-align: center;
}
.partner .gallery .line-bottom {
  width: 79px;
  height: 4px;
}
.partner .gallery .gallery-title {
  font-weight: bold;
}
.partner .img-gallery {
  height: 148px;
}
.partner .gallery-place {
  margin-top: 25px;
  padding: 15px;
}
.partner .gallery-place img {
  width: 100%;
}
.partner .gallery-place img.logo {
  width: 250px;
  padding: 15px;
  margin-left: 35px;
}
.partner .gallery-place img.logo-aurora {
  width: 220px;
  padding: 23px 15px 15px 15px;
  margin-left: 60px;
}
.partner .bold {
  font-weight: bold;
}
.partner .footer {
  background: #222;
  height: 100%;
  width: 80%;
  text-align: center;
  padding: 150px 0;
  margin-bottom: 100px;
}
.partner .footer .info-contact {
  letter-spacing: 5px;
}
.partner .footer a.contact {
  color: #fff;
  letter-spacing: 3px;
}
html {
  height: 100%;
  background: url('/images/assets/bg-login.jpg') no-repeat;
  background-size: 100% 100%;
  background-color: #e6e6e6;
}
.confirm-key {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
  background: #eeeeee;
  /* Old browsers */
  background: -moz-linear-gradient(top, #eeeeee 0%, #eeeeee 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#eeeeee', GradientType=0);
  /* IE6-9 */
  padding: 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 50%;
}
.confirm-key h1 {
  font-weight: bold;
}
.confirm-key .form-control {
  font-size: 50px;
  width: 50%;
  margin: 10px;
}
.confirm-key .btn-success {
  margin: 10px;
}
.confirm-key a.btn.btn-default {
  margin: 10px;
}
.confirm-key .verification {
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}
.news-ticker {
  position: relative;
}
.news-ticker .news {
  width: 700px;
  padding: 10px;
  right: 0;
  float: right;
}
.news-ticker .news ul {
  list-style: none;
  padding: 0;
}
.news-ticker .news ul li {
  overflow: hidden;
  padding: 5px 0;
}
.news-ticker .news ul li .img-container {
  display: block;
  float: left;
  width: 150px;
  height: 75px;
}
.news-ticker .news ul li .img-container img {
  width: 150px;
  height: 75px;
}
.news-ticker .news ul li .content {
  width: 540px;
  height: 75px;
  display: block;
  float: left;
  color: #ddd;
  font-size: 12px;
  color: white;
  padding: 0 5px;
}
.news-ticker .news ul li .content a {
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.news-ticker .news ul li .content p {
  margin: 0;
  line-height: 13px;
}
.news-article {
  width: 100%;
  height: 100%;
  color: #000000;
}
.news-article .bg-white {
  background: #fafafa;
  color: #333;
  min-height: 1204px;
}
.news-article .bg-gray {
  background: #333;
  height: auto;
}
.news-article .bg-black {
  background: #000;
  color: white;
  padding: 24px;
}
.news-article .sidebar-block > .title {
  color: #fff;
  border-bottom: 1px solid #2d88bacc;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 20px 0 20px;
}
.news-article .image-news {
  position: relative;
  margin-top: 25px;
  width: 100%;
  height: 570px;
  background-size: 100%;
}
.news-article .image-news:before {
  bottom: 0;
  content: "";
  display: block;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}
.news-article .image-news .title {
  position: absolute;
  bottom: 0px;
  left: 15px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-size: 44px;
  font-weight: bold;
  text-align: left;
  margin: 10px 50px 30px 30px;
  text-shadow: 1px 1px #333;
  z-index: 1;
}
.news-article .image-news .title .subtitle {
  font-size: 17px;
  font-weight: 400;
}
.news-article .image-news .share-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}
.news-article .image-news .share-button span {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}
.news-article .image-news .share-button a {
  margin: 10px 20px 10px 0px;
}
.news-article .image-news .share-button a img {
  width: 40px;
}
.news-article .article {
  text-align: left;
  padding: 30px 0 30px 30px;
  line-height: 35px;
}
.news-article .article img {
  width: 100%;
}
.news-article .article iframe {
  width: 100% !important;
}
.news-article .social-media .title-social {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
}
.news-article .social-media .social {
  display: inherit;
  color: #000;
  text-align: left;
}
.news-article .social-media i.fa.fa-twitter-square {
  color: #2e98d6;
  font-size: 45px;
  float: left;
  margin-right: 10px;
}
.news-article .social-media .google-plus {
  margin-top: 10px;
}
.news-article .social-media .google-plus i.fa.fa-google-plus-square {
  color: #d32222;
  font-size: 45px;
  float: left;
  margin-right: 10px;
}
.news-article .social-media .instagram {
  margin-top: 10px;
}
.news-article .social-media .instagram i.fa.fa-instagram {
  color: rgba(184, 6, 238, 0.979);
  font-size: 45px;
  float: left;
  margin-right: 10px;
}
.news-article ul.news-highlight {
  list-style: none;
  padding: 20px;
  margin: 0;
}
.news-article ul.news-highlight li {
  color: white;
  margin-bottom: 10px;
}
.news-article ul.news-highlight li img {
  width: 100%;
  margin-bottom: 5px;
}
.news-article ul.news-highlight li .title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
.news-article ul.news-highlight li .desc {
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}
.news-article ul.news-highlight li .link {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  padding: 10px 5px;
  border: 3px solid #fff;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 30px;
  display: block;
  width: 150px;
  text-align: center;
}
.news-article ul.news-highlight li .link:hover {
  background: white;
  color: #333;
  text-decoration: none;
}
.content-news-list {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-bottom: 20px;
  margin-top: 40px;
}
.content-news-list .image-header {
  position: relative;
  margin: 0;
  width: 100%;
  height: 340px;
  background-size: 100%;
  background: #333;
}
.content-news-list .image-header:before {
  bottom: 0;
  content: "";
  display: block;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}
.content-news-list .image-header img {
  background-size: 100%;
  width: 70%;
}
.content-news-list .image-header .image-side {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  padding: 20px;
  z-index: 1;
}
.content-news-list .image-header .image-side .title-header {
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  text-shadow: 1px 1px #333;
}
.content-news-list .image-header .image-side .title-header .subtitle-header {
  font-size: 12px;
  text-transform: none;
  font-weight: normal;
}
.content-news-list .image-header .image-side .content-header {
  color: #fff;
  text-align: justify;
  font-size: 12px;
  font-family: roboto, sans-serif;
}
.content-news-list .button-slide {
  position: absolute;
  bottom: 20px;
  right: 30px;
  overflow: hidden;
}
.content-news-list .button-slide .btn-ctrl {
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  /* font-weight: 700; */
}
.content-news-list .button-slide .btn-ctrl:hover {
  color: #fff;
  background: rgba(53, 53, 53, 0.055);
}
.content-news-list .button-slide .bx-prev {
  float: left;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  /* font-weight: 700; */
}
.content-news-list .button-slide .bx-prev:hover {
  color: #fff;
  background: rgba(53, 53, 53, 0.055);
}
.content-news-list .button-slide .bx-next {
  float: right;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  /* font-weight: 700; */
}
.content-news-list .button-slide .bx-next:hover {
  color: #fff;
  background: rgba(53, 53, 53, 0.055);
}
.content-news-list .newslist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style: none;
}
.content-news-list .newslist li {
  max-width: 33%;
}
.content-news-list .newslist .image-content {
  width: 100%;
  padding: 20px;
}
.content-news-list .newslist .image-content img {
  width: 100%;
  height: 100%;
  transition: -webkit-transform .2s;
  -moz-transition: transform .2s, -moz-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s, -moz-transform .2s;
  /* Animation */
}
.content-news-list .newslist .image-content img:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  -moz-transform: scale(1.1);
       transform: scale(1.1);
}
.content-news-list .newslist .title-content {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  padding: 0px 20px 0px 20px;
  text-align: left;
}
.content-news-list .newslist .title-content .title {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
}
.content-news-list .newslist .content {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 5px 20px 10px 20px;
  line-height: 20px;
  width: 360px;
  height: 185px;
  overflow: hidden;
}
.content-news-list .link {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  background: #333;
  font-family: 'Roboto', sans-serif;
  padding: 7px;
  border: 3px solid #fff;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
  width: 130px;
  text-align: center;
  font-size: 11px;
}
.content-news-list .link:hover {
  background: white;
  color: #333;
  text-decoration: none;
  border: 3px solid #333;
}
.content-news-list .highlight-topic {
  font-weight: 700;
  background: #0e8fe6;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  margin: 20px 11px 0 0;
  width: 200px;
  text-align: center;
  font-family: Roboto, sans-serif;
}
.content-news-list .line {
  margin: 0 0px 10px 0px;
  border-bottom: 3px solid #0e8fe6;
}
.content-news-list .image-other {
  margin: 0pc 0px 10px 20px;
  width: 100%;
  height: 100%;
}
.content-news-list .image-other img {
  width: 100%;
  height: 100%;
}
.content-news-list .other-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.content-news-list .other-content {
  width: 60%;
  font-family: 'Roboto', sans-serif;
}
.content-news-list .other-content p {
  font-size: 13px;
  margin-bottom: 2px;
}
.content-news-list .other-content .info {
  font-size: 12px;
}
.studio {
  position: relative;
  color: white;
  background: #333;
}
.studio .header-blue {
  background-image: url(/images/assets/header-blue.png);
  background-repeat: no-repeat;
  background-size: 70%;
  height: 300px;
  color: #000;
  background-color: #efefef;
}
.studio .header-blue .studio-title-container {
  margin: 0;
  padding-top: 15px;
}
.studio .header-blue .studio-title-container .content {
  position: relative;
  top: 40px;
}
.studio .header-blue .studio-title-container .studio-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-right: 5px;
}
.studio .header-blue .studio-title-container .studio-text {
  font-size: 55px;
  font-weight: bold;
  margin: 0;
  color: white;
}
.studio .header-blue .studio-title-container .logo-title {
  width: 17%;
  display: block;
  margin-top: 15px;
}
.studio .header-blue .studio-title-container .studio-number {
  text-align: center;
  line-height: 1.1;
}
.studio .header-blue .studio-title-container .studio-number .number {
  font-size: 200px;
  font-family: arial;
  font-weight: 700;
}
.studio .header-blue .studio-title-container .studio-number .text {
  line-height: 0;
  font-weight: 700;
  font-size: 40px;
  display: block;
}
.studio .header-green {
  background-image: url(/images/assets/header-green.png);
  background-repeat: no-repeat;
  background-size: 70%;
  height: 300px;
  color: #ffffff;
  background-color: #13498c;
}
.studio .header-green .studio-title-container {
  margin: 0;
  padding-top: 15px;
}
.studio .header-green .studio-title-container .content {
  position: relative;
  top: 40px;
}
.studio .header-green .studio-title-container .studio-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}
.studio .header-green .studio-title-container .studio-text {
  font-size: 55px;
  font-weight: bold;
  margin: 0;
}
.studio .header-green .studio-title-container .logo-title {
  width: 17%;
  display: block;
  margin-top: 15px;
}
.studio .header-green .studio-title-container .studio-number {
  text-align: center;
  line-height: 1.1;
}
.studio .header-green .studio-title-container .studio-number .number {
  font-size: 200px;
  font-family: arial;
  font-weight: 700;
  background-color: #000;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: #fff 3px 3px 0;
}
.studio .header-green .studio-title-container .studio-number .text {
  line-height: 0;
  font-weight: 700;
  font-size: 40px;
  display: block;
}
.studio .header-purple {
  background-image: url(/images/assets/header-purple.png);
  background-repeat: no-repeat;
  background-size: 70%;
  height: 300px;
  background-position: 100%;
  color: #fff;
  background-color: #333;
}
.studio .header-purple .studio-title-container {
  margin: 0;
  padding-top: 15px;
}
.studio .header-purple .studio-title-container .content {
  position: relative;
  top: 40px;
}
.studio .header-purple .studio-title-container .studio-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}
.studio .header-purple .studio-title-container .studio-text {
  font-size: 55px;
  font-weight: bold;
  margin: 0;
}
.studio .header-purple .studio-title-container .logo-title {
  width: 17%;
  display: block;
  margin-top: 15px;
}
.studio .header-purple .studio-title-container .studio-number {
  text-align: center;
  line-height: 1.1;
}
.studio .header-purple .studio-title-container .studio-number .number {
  font-size: 200px;
  font-family: arial;
  font-weight: 700;
  background-color: #333;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: #fff 3px 3px 0;
}
.studio .header-purple .studio-title-container .studio-number .text {
  line-height: 0;
  font-weight: 700;
  font-size: 40px;
  display: block;
}
.studio .nav-studio {
  background: #4b5156;
  margin: 0;
}
.studio .nav-studio .navbar-nav {
  list-style: none;
  font-size: 20px;
  padding: 10px;
}
.studio .nav-studio .nav-link {
  list-style: none;
  color: white;
  padding: 15px;
  font-size: 17px;
}
.studio .nav-studio a:hover {
  text-decoration: none;
  color: #ccc;
  cursor: pointer;
}
.studio .studio-content {
  background: white;
  padding: 15px;
}
.studio .studio-content .studio-content-image {
  width: 100%;
  margin: 20px 0;
}
.studio .studio-content .studio-content-text {
  color: #000;
  margin-top: 20px;
}
.studio .studio-content .studio-content-text .studio-content-title {
  font-size: 30px;
  font-weight: bold;
  display: block;
}
.studio .studio-content .studio-content-text .line {
  display: block;
  margin: 10px 0;
}
.studio .studio-content .studio-content-text .line-right {
  margin: 10px 0;
}
.studio .studio-content .studio-content-text .text-left {
  text-align: left;
  font-size: 1.7em;
  line-height: 40px;
}
.studio .studio-content .studio-content-text .text-right {
  text-align: right;
  font-size: 1.7em;
  line-height: 40px;
}
.studio .title-gallery {
  font-size: 30px;
  font-weight: bolder;
}
.studio .line-gallery {
  display: block;
  margin: 10px auto;
}
.studio .popup {
  border: none;
  width: 24%;
  min-height: 198px;
  max-height: 198px;
  margin: 0.5%;
  overflow: hidden;
}
.studio .popup .pict-gallery {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.studio .spec {
  background: #fff;
  color: #000;
  margin: 30px 0 0 0;
}
.img-header-help {
  background: url(/images/assets/bg-partnership.png);
  background-size: 100% ;
  padding-bottom: 5%;
}
.header-help {
  padding-bottom: 11%;
}
.header-help .title-header-help {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  font-family: sans-serif;
  margin: 0 auto;
  width: 550px;
  height: 60px;
  margin-top: 12%;
}
.header-help .search-input-help {
  width: 600px;
  margin: 0 auto;
  margin-top: 1%;
  position: relative;
  display: table;
  border-collapse: separate;
}
.list-help {
  font-family: sans-serif;
  margin: 0;
}
.body-help {
  background: #fff;
  color: #000;
  padding: 40px 60px;
  margin-bottom: 5%;
}
.body-help [aria-expanded="false"] .plus-icon {
  display: block;
}
.body-help [aria-expanded="false"] .minus-icon {
  display: none;
}
.body-help [aria-expanded="true"] .plus-icon {
  display: none;
}
.body-help [aria-expanded="true"] .minus-icon {
  display: block;
}
.body-help .question-container {
  border-radius: 3px;
  margin: 1px 0;
  padding: 5px;
}
.body-help .question-container i {
  position: absolute;
  color: #00aeef;
  margin-top: 3px;
  font-size: 15px;
}
.body-help .question-container .question-container[aria-expanded] {
  height: 240px;
}
.body-help .question-container .question {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  min-height: 20px;
  font-weight: bold;
  margin-left: 20px;
  border-bottom: 1px solid #ccc;
}
.body-help .well {
  background: #efefef;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}
.modal-title {
  text-transform: uppercase;
}
.single-page-content {
  position: relative;
}
.background-mv-image {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 530px;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
  padding: 0;
}
.single-info-movie {
  position: absolute;
  top: 300px;
  width: 1100px;
}
.single-info-movie .single-cover-mv {
  position: relative;
}
.single-info-movie .single-cover-mv .status-movie {
  top: 0;
  left: 0;
  background-size: 100% 100%;
  width: 140px;
  height: 140px;
  opacity: 2;
  position: absolute;
}
.single-info-movie .single-cover-mv .status-movie#upcoming {
  background-image: url("/images/assets/upcoming.png");
  z-index: 1;
}
.single-info-movie .single-cover-mv .status-movie#nowplaying {
  background-image: url("/images/assets/nowplaying.png");
  z-index: 1;
}
.cover-mv-img {
  padding: 0;
  width: 100%;
  height: 530px;
}
.single-mv-content {
  color: white;
  background: rgba(0, 0, 0, 0.84);
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.84) 0%, #000000 21%, rgba(0, 0, 0, 0.77) 52%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0.84)), color-stop(21%, #000000), color-stop(52%, rgba(0, 0, 0, 0.77)), color-stop(100%, rgba(0, 0, 0, 0)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.84) 0%, #000000 21%, rgba(0, 0, 0, 0.77) 52%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1);
  padding-top: 20px;
  padding-left: 25px;
  height: 341px;
  width: 800px;
}
.movie-title {
  top: -310px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
.movie-title #notdisplay {
  display: none;
}
.single-mv-tag {
  text-align: center;
  width: 25px;
  line-height: 25px;
  font-weight: bold;
  background-color: #ff7b00;
  color: white;
  position: absolute;
}
.tba {
  font-weight: bold;
  color: #ff7b00;
  padding-left: 7px;
  font-family: sans-serif;
}
.mv-time {
  font-weight: bold;
  color: #ff7b00;
  padding-left: 25px;
  font-family: sans-serif;
}
.padding-0 {
  padding: 0;
}
.info-list {
  margin-top: 30px;
}
.info-list tr .desc-top-single {
  padding-left: 10px;
}
.info-list tr .desc-top-single span {
  color: #cecece;
  font-weight: bold;
  font-size: 14px;
}
.info-synopsis {
  margin-bottom: auto;
  min-height: 200px;
}
.info-synopsis .synopsis-header {
  margin-top: 15px;
  padding-left: 10px;
}
.info-synopsis .synopsis-header .synopsis-title {
  position: relative;
  color: #ff7b00;
  font-family: inherit;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 3px solid #fe7900;
}
.info-synopsis .synopsis-header .synopsis-title:after {
  position: absolute;
  content: "";
  width: 42px;
  height: 3px;
  background-image: url(/images/lines2.png);
  background-color: transparent;
  background-repeat: no-repeat;
  bottom: -3px;
  right: 0;
}
.info-synopsis .synopsis-header .synopsis-share {
  font-weight: bold;
  float: right;
  font-size: 14px;
  padding-top: 10px;
}
.info-synopsis .synopsis-header .synopsis-share .icon {
  color: rgba(66, 66, 66, 0.603);
  margin-left: 10px;
}
.info-synopsis .synopsis-header .synopsis-share .icon a:hover {
  color: #ff7b00;
}
.info-synopsis .synopsis-content {
  margin-left: 10px;
  margin-top: 15px;
  max-height: 240px;
  overflow-y: auto;
}
.info-synopsis .btn-group-single {
  margin-top: 10px;
}
.info-synopsis .btn-orange {
  margin-top: 15px;
  background-color: transparent;
  display: inline;
}
.info-synopsis .btn-orange .btn:hover {
  background-color: #ff7b00;
  border: 1px solid #ff7b00;
}
.info-synopsis .btn-green {
  margin-top: 15px;
  font-size: 14px;
  color: gray;
  min-height: 100px;
}
.info-cast .cast-header .col-md-12 {
  width: 1170px;
  overflow: hidden;
}
.info-cast .cast-header .col-md-12 .cast-catalog {
  margin-top: 20px;
  display: -webkit-box;
  overflow-x: scroll;
}
.info-cast .cast-header .col-md-12 .cast-catalog .cast-img {
  width: 130px;
  height: 180px;
  margin-right: 5px;
  margin-bottom: 20px;
}
.cast-title {
  position: relative;
  color: #ff7b00;
  font-family: inherit;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 3px solid #fe7900;
}
.cast-title:after {
  position: absolute;
  content: "";
  width: 42px;
  height: 3px;
  background-image: url(/images/lines2.png);
  background-color: transparent;
  background-repeat: no-repeat;
  bottom: -3px;
  right: 0;
}
.btn-single-trailer {
  font-size: 11px;
  color: white;
  border: 1px solid #ffffff5e;
}
.btn.btn-single-buy-ticket {
  font-size: 11px;
  color: white;
  border: 1px solid #ffffff5e;
  margin-left: 10px;
}
.btn-orange {
  margin-top: 15px;
  background-color: transparent;
  display: inline;
}
.btn-orange .btn:hover {
  background-color: #ff7b00;
  border: 1px solid #ff7b00;
}
.btn-green {
  margin-top: 15px;
  background-color: transparent;
  display: inline;
}
.btn-green .btn:hover {
  background-color: #00c400;
  border: 1px solid #00c400;
}
.cast-catalog {
  margin-top: 20px;
  display: -webkit-box;
}
.cast-img {
  width: 130px;
  height: 180px;
  margin-right: 5px;
  margin-bottom: 20px;
}
.cast-img:hover {
  border: 3px solid #ff7b00;
  cursor: pointer;
}
.info-showtime {
  margin-top: 20px;
}
.showtime-header {
  margin-top: 40px;
}
.select-studio {
  height: 44px;
  background-color: #eeeeee;
  margin-bottom: 15px;
  margin-top: 15px;
}
.select-studio li {
  padding: 12px;
}
.select-studio span {
  font-weight: bold;
  color: #000000;
  font-size: 16px;
  margin-left: 10px;
  display: inline;
}
.showtime {
  position: relative;
}
.showtime .showtime-item:hover {
  background-color: #cecece;
}
.showtime .showtime-active:after {
  right: -10px;
  top: 7px;
  border: solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #cecece;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.showtime-content {
  color: #000000;
}
.showtime-item {
  height: 34px;
  padding: 5px;
  border-radius: 2px;
  padding-left: 20px;
  background-color: #eeeeee;
  position: relative;
}
.showtime-item:hover {
  cursor: pointer;
}
.showtime-item:hover .showtime-link {
  font-weight: bold;
}
.showtime-active {
  background-color: #cecece;
  font-weight: bold;
}
.showtime-link {
  color: #000000;
  font-size: 13px;
}
.showtime-active-content {
  margin-top: 15px;
  padding: 5px;
  padding-top: 10px;
  width: 100%;
  border-radius: 2px;
  padding-left: 20px;
  background-color: #eee;
  min-height: 466px;
}
.showtime-active-content ul {
  margin-bottom: 4px;
}
.showtime-active-content ul li {
  background-color: #dbdbdb;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
  padding: 8px 30px;
  margin: 0 6px 9px 0;
  list-style: none;
  display: inline-block;
  border: 0;
}
.showtime-active-content .date {
  font-size: 24px;
  font-weight: bolder;
  border-bottom: 1px solid #bbb;
  margin-bottom: 5px;
}
.sub-decs {
  margin-bottom: 20px;
}
.calender-single {
  color: #ff7b00;
  font-size: 20px;
}
.studio-name {
  font-weight: bold;
  font-size: 17px;
}
.other-films-img {
  width: 220px;
}
.btn-detail-other-films {
  margin-left: 20px;
}
.btn.btn-single-trailer {
  padding-left: 5px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #ffffff5e;
}
.btn.btn-single-buy-ticket {
  font-size: 14px;
  color: #fff;
  border: 1px solid #ffffff5e;
  padding-left: 5px;
}
.list-movie {
  margin-top: 20px;
  margin-bottom: 25px;
}
.list-movie-image {
  position: relative;
}
.list-movie-image .wrap {
  margin-top: 0;
}
.list-movie-image:hover .icon-watch-trailer {
  opacity: 1;
  cursor: pointer;
}
.list-movie-image .movie-image-other-films {
  width: 270px;
  height: 430px;
}
.list-movie-image .icon-watch-trailer {
  top: 0;
  left: 0;
  position: absolute;
  width: 227px;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.507);
  opacity: 0;
  z-index: 1000;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.frame ul li {
  padding: 6px;
}
.frame ul .active .divided-image {
  outline: 5px solid #ff7b00;
}
.tooltipster-base {
  width: 450px;
  height: 250px;
}
.warning-text {
  color: gray !important;
  font-size: 13px !important;
  line-height: 13px;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}
.lounge-title {
  position: relative;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.lounge-title span {
  font-size: 30px;
  color: #ff7b00;
  font-weight: bold;
}
.lounge-title:before {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #ff7b00;
  width: 440px;
  left: 0;
  top: -moz-calc(49%);
  top: calc(49%);
}
.lounge-title:after {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #ff7b00;
  width: 440px;
  right: 0;
  top: -moz-calc(49%);
  top: calc(49%);
}
.lounge-about-us {
  text-align: center;
}
.lounge-about-us .view-lounge {
  height: 234px;
}
.lounge-about-us .view-lounge-title {
  margin-top: 20px;
  margin-bottom: 0;
  color: #ff7b00;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 23px;
  margin-bottom: 10px;
}
.lounge-about-us .view-lounge-text {
  color: gray;
  font-family: sans-serif;
  font-size: 14px;
}
.lounge-nav {
  margin: 20px 70px 0 70px;
}
.lounge-nav .lounge-nav-item {
  text-align: center;
}
.lounge-nav .lounge-nav-item a {
  width: 100px;
  height: 100px;
  padding: 13px;
  border-radius: 50px;
  margin: 0 auto;
  background-color: gray;
  display: block;
}
.lounge-nav .lounge-nav-item a img {
  width: 70px;
}
.lounge-nav .lounge-nav-item a:hover {
  background-color: #ff7b00;
  -moz-transition: 1s;
  transition: 1s;
}
.lounge-nav .lounge-nav-item a .coffee-img {
  width: 74px;
  padding-top: 6px;
}
.lounge-nav .lounge-nav-item .lounge-nav-item-title {
  color: #ff7b00;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}
.lounge-nav .lounge-nav-item .lounge-nav-text {
  color: #8a8a8a;
  font-size: 14px;
}
.lounge-nav .lounge-nav-item-drink {
  text-align: center;
}
.lounge-nav .lounge-nav-item-drink a {
  width: 100px;
  height: 100px;
  padding: 13px;
  border-radius: 50px;
  margin: 0 auto;
  background-color: gray;
  display: block;
}
.lounge-nav .lounge-nav-item-drink a img {
  width: 33px;
}
.lounge-nav .lounge-nav-item-drink a:hover {
  background-color: #ff7b00;
  -moz-transition: 1s;
  transition: 1s;
}
.lounge-nav .lounge-nav-item-drink .lounge-nav-item-title {
  color: #ff7b00;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}
.lounge-nav .lounge-nav-item-drink .lounge-nav-text {
  color: #8a8a8a;
  font-size: 14px;
}
.lounge-foto-left {
  background-color: #ff7b00;
}
.lounge-foto-left .food-pict img {
  width: 445px;
  height: 285px;
  margin-left: -15px;
}
.lounge-foto-left .food-desc {
  padding-top: 70px;
  padding-right: 0;
  padding-left: 11px;
}
.lounge-foto-left .food-desc .menu-title {
  color: white;
  font-weight: bold;
}
.lounge-foto-left .food-desc .menu-decs {
  color: white;
}
.lounge-foto-left .food-float-right {
  text-align: right;
  padding-left: 0;
  padding-right: 30px;
}
.lounge-foto-right {
  background-color: #222222;
}
.lounge-foto-right .food-pict img {
  width: 445px;
  height: 285px;
  margin-left: 15px;
}
.lounge-foto-right .food-desc {
  padding-top: 70px;
  padding-right: 0;
  padding-left: 38px;
}
.lounge-foto-right .food-desc .menu-title {
  color: white;
  font-weight: bold;
}
.lounge-foto-right .food-desc .menu-decs {
  color: white;
}
.lounge-foto-right .food-float-right {
  text-align: right;
  padding-left: 0;
  padding-right: 30px;
}
.lounge-foto-sec img {
  margin: 10px 0;
  width: 255px;
  height: 155px;
}
.menu-list-lounge .menu-lounge-title {
  margin-top: 30px;
  color: #ff7b00;
  text-transform: uppercase;
}
.menu-list-lounge .menu-lounge-title h2 {
  font-weight: bold;
}
.menu-list-lounge .menu-lounge-desc {
  color: gray;
}
.header-result-page {
  height: 245px;
  color: white;
  background-color: #313131;
  position: relative;
  margin-bottom: 20px;
}
.result-list {
  margin-bottom: 30px;
}
.result-list .col-md-12 {
  text-align: center;
  padding: 133px 0;
  min-height: 465px;
}
.result-list .col-md-12 span {
  display: block;
}
.result-list .col-md-12 .eng {
  font-style: italic;
}
.result-page-title {
  font-weight: 700;
  top: 105px;
  left: 140px;
  position: absolute;
  font-size: 28px;
}
.result-page-text {
  top: 240px;
  left: 140px;
  position: absolute;
  font-size: 20px;
}
.content-result-page {
  width: 100%;
}
.result-detail .result-movie-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.result-detail .mv-tag {
  left: 0px;
  text-align: center;
  width: 30px;
  line-height: 30px;
  font-weight: 700;
  top: 30px;
  background-color: #ff7b00;
  color: #fff;
  position: absolute;
}
.result-detail .mv-time {
  padding-left: 40px;
}
.result-detail ul {
  margin-top: 25px;
}
.result-detail ul li label {
  color: #ff7b00;
}
.result-detail ul li span {
  color: #000000;
}
.result-detail p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  max-height: 120px;
  min-height: 100px;
  overflow-y: auto;
}
.result-cover-movie {
  width: 100%;
}
.bg-grey {
  background-color: #313131;
}
.option-search {
  margin: 40px 0;
}
.title-with-line {
  position: relative;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 24px;
}
.title-with-line:before {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #ff7b00;
  width: 450px;
  left: 0;
  top: 17px;
}
.title-with-line:after {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #ff7b00;
  width: 450px;
  right: 0;
  top: 17px;
}
.result-cover-movie {
  position: relative;
}
.icon-now-playing {
  position: absolute;
  top: 0;
}
.icon-upcoming {
  position: absolute;
  top: 0;
}
.tag-d {
  background-color: #ff0000;
}
.tag-r {
  background-color: #006cf7;
}
.tag-su {
  background-color: #00c400;
}
.icon-upcoming {
  position: absolute;
  top: 0;
}
.icon-upcoming .upcoming {
  width: 110px;
}
html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  background-color: white;
}
.page-container {
  max-width: 100%;
  height: 100%;
}
.top-container {
  height: 39px;
  background: black;
  width: 100%;
}
.top-container .top-content-container {
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.well {
  display: block;
  padding: 20px 0;
}
.italic {
  font-style: italic;
}
.header-container {
  height: 79px;
  width: 100%;
  position: relative;
}
.header-container nav {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
}
.header-container .header-content-container {
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
ul {
  list-style-type: none;
  padding: 0;
}
